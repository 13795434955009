<template>
  <div style="padding: 50px">
    <center>
      <div class="box">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <h1>注册</h1>
              <small>您需要注册</small>
            </div>
          </div>
        </article>
      </div>
      <div class="card" style="width: 400px; margin-top: 3%">
        <div class="card-content">
          <b-field label="Email" :label-position="labelPosition">
            <b-input type="email" v-model="email" maxlength="30"> </b-input>
          </b-field>
          <b-field label="Username" :label-position="labelPosition">
            <b-input type="text" v-model="username" maxlength="30"> </b-input>
          </b-field>
          <b-field label="Password" :label-position="labelPosition">
            <b-input type="password" v-model="password" password-reveal>
            </b-input>
          </b-field>
          <b-field label="邀请🐴(选填)" :label-position="labelPosition">
            <b-input type="text" v-model="invite"> </b-input>
          </b-field>
          <!-- <vue-recaptcha :sitekey="sitekey" @verify="onVerify"></vue-recaptcha> -->
        </div>

        <footer class="card-footer">
          <!-- <b-button type="is-primary" tag="router-link" :to="{ path: '/node' }" expanded>跳转</b-button> -->
          <b-button type="is-primary" @click="login" expanded>注册</b-button>
        </footer>
      </div>
      <br />
      <br />
      <div>
        <small>有账号？</small>
        <br />
        <b-button type="is-text" tag="router-link" :to="{ path: '/' }"
          >登陆</b-button
        >
      </div>
    </center>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { sitekey } from "../config.json";
export default {
  components: { VueRecaptcha },
  data() {
    return {
      email: "",
      password: "",
      username: "",
      invite: "",
      labelPosition: "on-border",
      VerRes: "",
      sitekey: sitekey,
    };
  },
  methods: {
    onVerify: function (response) {
      this.VerRes = response;
      console.log("Verify: " + response);
    },
    async login() {
      if (this.VerRes == "") {
        // return this.$buefy.dialog.alert("你还没有点验证码！");
      }
      let res = await this.$http.post("/api/users/register", {
        email: this.email,
        password: this.password,
        invite: this.invite,
        username: this.username,
      });
      this.$router.push("/");
    },
  },
};
</script>

<style>
</style>